import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Hero, WhatWeDo, Companies } from "../components/main";
import TrustBoxWidget from "../components/trustbox";

export const SolarSpecialTemplate = ({
  whatwedo,
  companies,
  trustbox,
  special
}) => {
  const newHero = {
    heading: special.title,
    subheading: special.subtitle,
    image: {
      img: special.heroimage,
      text: ""
    },
    buttontext: special.herobuttontext,
    bgColor: special.hero_bgcolor
  };

  return (
    <>
      <Hero {...newHero} />
      <WhatWeDo {...whatwedo} />
      <Companies items={companies} />
      <TrustBoxWidget title={trustbox.carouseltitle} widget="carousel" />
    </>
  );
};

SolarSpecialTemplate.propTypes = {
  special: PropTypes.object,
  title: PropTypes.string,
  whatwedo: PropTypes.object,
  companies: PropTypes.array,
  trustbox: PropTypes.object
};

const SolarSpecialPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { frontmatter: special } = data.special;

  return (
    <Layout headerOpts={{ phoneOnly: true }} location={location}>
      <SolarSpecialTemplate {...frontmatter} special={special} />
    </Layout>
  );
};

SolarSpecialPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  }),
  location: PropTypes.object
};

export default SolarSpecialPage;

export const solarSpecialPageQuery = graphql`
  query SolarSpecialPage($id: String!) {
    special: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        headerbuttontext
        herobuttontext
        hero_bgcolor
        heroimage {
          childImageSharp {
            fluid(maxWidth: 550) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "main-page" } }) {
      frontmatter {
        templateKey
        meta {
          name
          content
        }
        headerbuttontext
        whatwedo {
          title
          items {
            title
            text
            img {
              childImageSharp {
                fixed(width: 250) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        companies {
          companyname
          img {
            childImageSharp {
              fixed(width: 350) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
        trustbox {
          carouseltitle
        }
      }
    }
  }
`;
